<template lang="pug">
.landing-page__block
  .landing-page__benefits-widget
    .benefits-widget__block
      .benefits-widget__icon
        ui-icon(:icon="UiIconNames.Landing_NewOrders" :size="22")
      span Находите <br /> новые заказы
    .benefits-widget__block
      .benefits-widget__icon
        ui-icon(:icon="UiIconNames.Landing_MoneyBag" :size="22")
      span Уменьшайте <br /> издержки
    .benefits-widget__block
      .benefits-widget__icon
        ui-icon(:icon="UiIconNames.Landing_Monitor" :size="22")
      span Работайте <br /> эффективно
    .benefits-widget__block
      .benefits-widget__icon
        ui-icon(:icon="UiIconNames.Landing_Trophy" :size="22")
      span Обходите <br /> конкурентов
</template>

<script lang="ts">
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import { defineComponent } from "vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SystemCapabilitiesIntro",
  components: {
    UiIcon,
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/landing/landing";

.landing-page__benefits-widget {
  border-radius: 20px;
  display: flex;
  padding: 30px 60px;
  background-color: #eef5ff;
  justify-content: space-between;

  span {
    font-size: 16px;
    color: #324059;
  }

  .benefits-widget__icon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color-white);
  }

  .benefits-widget__block {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}

@media (max-width: 1000px) {
  .landing-page__benefits-widget {
    padding: 30px 44px;
    .benefits-widget__block {
      gap: 16px;
    }
  }
}

@media (max-width: 860px) {
  .landing-page__benefits-widget {
    .benefits-widget__block {
      flex-flow: column;
      text-align: center;
    }
  }
}

@media (max-width: 640px) {
  .landing-page__benefits-widget {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 16px 24px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    .benefits-widget__block {
      flex-flow: row;
      text-align: inherit;
    }
  }
}

@media (max-width: 500px) {
  .landing-page__benefits-widget {
    display: flex;
    flex-flow: column;
    gap: 12px;

    .benefits-widget__block {
      span br {
        display: none;
      }
    }
  }
}

</style>
