<template lang="pug">
.landing-page__block
  .title-block.center.column
    .title Почтовые уведомления по самым важным событиям
    .subtitle Настраивайте так, как удобно - чтобы ничего не пропустить.
    //ui-button.blue-button(@click="showDialog('registration')") Зарегистрироваться
  .about-system
    .info
      span.main Автоматические уведомления о важных событиях
      span Email рассылки о новых закупках, напоминания о сроках подачи заявок, о торгах, о подписании контрактов
      span Гибкая система рассылок - выбор времени и частоты отправки уведомлений
    .images
      img.notification(src="/pics/landing/notifications.png")
      img.notification-history(src="/pics/landing/notifications-history.png")
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useLandingLogin } from "~/use/auth/useLandingLogin";

import UiButton from "~/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "AboutNotifications",
  components: {
    UiButton,
  },
  setup() {

    const { showDialog } = useLandingLogin();

    return {
      showDialog,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/landing/landing";
@import '@/assets/styles/landing/button';

.about-system {

  .img-notification {
    width: 400px;
  }

  .info {
    bottom: 0;
    right: 0;
    z-index: 10;
  }

  .images {
    .notification-history {
      width: 100%;
      max-width: 700px;
      z-index: 1;
    }

    .notification {
      width: 70%;
      max-width: 420px;
      position: absolute;
      top: -74px;
      z-index: 2;
      right: 20%;
    }
  }
}

@media (max-width: 640px) {
  .about-system {
    .images {
      .notification {
        max-width: 240px;
        bottom: 80px;
        right: 20px;
        top: unset;
      }
    }
  }
}
</style>
