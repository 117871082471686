<template lang="pug">
#contacts.landing-page__block
  .map-block
    .landing-page__map-wrapper
      .landing-page__yandex-map
        div(style="position:relative;overflow:hidden;")
          a(href="https://yandex.ru/maps/65/novosibirsk/search/%D0%BD%D0%BF%D0%BE%20%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0%20%D1%81%D0%BF%D1%80%D0%B0%D0%B2%D0%BE%D1%87%D0%BD%D0%B8%D0%BA/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;") нпо система справочник в Новосибирске
          a(href="https://yandex.ru/maps/65/novosibirsk/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;") Новосибирск
          iframe(src="https://yandex.ru/map-widget/v1/?ll=82.580360%2C54.935698&mode=search&sctx=ZAAAAAgBEAAaKAoSCVIoC19ftlRAEQRY5NcPeUtAEhIJU%2BdR8X9H6j8R0lEOZhNg1T8iBgABAgMEBSgKOABA6I4GSAFqAnJ1nQHNzEw9oAEAqAEAvQGzkQIywgEPsbD%2Fp2WynbHfA%2Bea%2BPAD6gEA8gEA%2BAEAggIq0L3Qv9C%2BINGB0LjRgdGC0LXQvNCwINGB0L%2FRgNCw0LLQvtGH0L3QuNC6igIAkgIAmgIMZGVza3RvcC1tYXBz&sll=82.580360%2C54.935698&sspn=2.306739%2C0.938384&text=%D0%BD%D0%BF%D0%BE%20%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0%20%D1%81%D0%BF%D1%80%D0%B0%D0%B2%D0%BE%D1%87%D0%BD%D0%B8%D0%BA&z=9.51" width="560" height="400" allowfullscreen="true" style="position:relative; border: 0; width: 100%;")
    .map-label
      .wrapper
        .secondary-title
          | Более
          |
          a.map-link(href="https://nposys.ru/references/" target="_blank") 50 подтвержденных
          |
          | отзывов!
        .landing-page__divider
          span._separator
      .wrapper
        .secondary-title Остались вопросы? Свяжитесь с нами
        support-contacts.contact-wrapper(mode="map" size="large")

</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import SupportContacts from "@/components/support/SupportContacts.vue";

export default defineComponent({
  name: "YandexMap",
  components: {
    SupportContacts,
    UiIcon,
  },
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/landing/landing";

.landing-page__map-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.landing-page__block {
  align-items: start;
}

.landing-page__yandex-map {
  width: 100%;
  max-width: 1024px;
}

.wrapper {
  display: contents;
}

.landing-page__divider {
  justify-content: start;
}

.map-block {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 72px;

  .map-label {
    display: flex;
    flex-flow: column;
    gap: 16px;

    color: var(--main-text-color);

    .contact-wrapper {
      margin-top: 12px;
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    display: flex;
    flex-flow: column;
    gap: 32px;
  }
  .map-block {
    display: flex;
    flex-flow: column-reverse;
    gap: 32px;
    width: 100%;

    .map-label {
      flex-flow: row;
      gap: 64px;

      .contact-wrapper {
        gap: 8px;
      }
    }
  }
}

@media (max-width: 640px) {
  .wrapper {
    display: contents;
  }
  .map-block {
    .map-label {
      flex-flow: column;
      gap: 16px;
    }
  }
}
</style>
