<template lang="pug">
.landing-page__block
  .title-block.center.column
    span.title Широкий выбор фильтров для поиска тендеров
    span.subtitle Быстрый поиск по ключевым словам и отраслям, расширенный поиск по цене, площадкам, законам, и прочим фильтрам
    //ui-button.blue-button(@click="showDialog('registration')") Искать бесплатно
  img.img1(src="/pics/landing/search-form.png")
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UiButton from "~/components/ui/button/UiButton.vue";
import { useLandingLogin } from "~/use/auth/useLandingLogin";

export default defineComponent({
  name: "AboutSearch",
  components: {
    UiButton,
  },
  setup() {

    const { showDialog } = useLandingLogin();

    return {
      showDialog,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/landing/landing";
@import '@/assets/styles/landing/button';

img {
  @include system-image;
  max-width: var(--main-content-width);
}
</style>
